import * as React from 'react'
import PropTypes from 'prop-types'

export function PrimaryButton (props) {
  return (
    <button
      type="button"
      className="md:mt-0 text-white bg-pink-600 hover:bg-pink-700 focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2 text-center"
    >
      {props.children}
    </button>
  )
}

PrimaryButton.propTypes = {
  children: PropTypes.node
}
