import * as React from 'react'
import { Layout } from '../components/layout'
import { PageHeader } from '../components/pageHeader'
import { OddRow, EvenRow } from '../components/snippets/tables'

const ContactPage = () => {
  return (
    <Layout>
      <PageHeader>Contact </PageHeader>
      <div className={'container mx-auto md:w-1/2'}>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <tbody>
                    <EvenRow>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                        First aid
                      </td>
                      <td className="text-sm  font-light px-6 py-4 whitespace-nowrap">
                        Daniel Hartwig <br />
                        Justin Hohmann
                        <br />
                        Dieter Haupt
                        <br />
                        Malte Hagemann
                      </td>
                    </EvenRow>
                    <OddRow>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                        Health & Safety
                      </td>
                      <td className="text-sm  font-light px-6 py-4 whitespace-nowrap">
                        Julian Gurs <br /> Justin Hohmann
                      </td>
                    </OddRow>
                    <EvenRow>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                        {' '}
                        Laser Safety
                      </td>
                      <td className="text-sm  font-light px-6 py-4 whitespace-nowrap">
                        Roman Schnabel
                      </td>
                    </EvenRow>
                    <OddRow>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                        {' '}
                        E-Workshop & Electronics Safety
                      </td>
                      <td className="text-sm  font-light px-6 py-4 whitespace-nowrap">
                        Dieter Haupt
                      </td>
                    </OddRow>
                    <EvenRow>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                        {' '}
                        Ordering
                      </td>
                      <td className="text-sm  font-light px-6 py-4 whitespace-nowrap">
                        Mikhail Korobko <br />
                        Axel Schoenbeck <br />
                        Justin Hohmann (for QU)
                      </td>
                    </EvenRow>
                    <OddRow>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                        {' '}
                        Web services
                      </td>
                      <td className="text-sm  font-light px-6 py-4 whitespace-nowrap">
                        Alexander Franke
                      </td>
                    </OddRow>
                    <EvenRow>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                        {' '}
                        Calendar
                      </td>
                      <td className="text-sm  font-light px-6 py-4 whitespace-nowrap">
                        Pascal Gewecke
                      </td>
                    </EvenRow>
                    <OddRow>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                        {' '}
                        Gitlab
                      </td>
                      <td className="text-sm  font-light px-6 py-4 whitespace-nowrap">
                        <a
                          href={
                            'https://www.physik.uni-hamburg.de/th1/personen/maiwald-lorna.html'
                          }
                        >
                          {' '}
                          Lorna Maiwald
                        </a>
                      </td>
                    </OddRow>
                    <EvenRow>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                        {' '}
                        Physnet Email / Internet / Laptops
                      </td>
                      <td className="text-sm  font-light px-6 py-4 whitespace-nowrap">
                        admin@physnet.uni-hamburg.de
                      </td>
                    </EvenRow>
                    <OddRow>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                        {' '}
                        nqlab & openqlab
                      </td>
                      <td className="text-sm  font-light px-6 py-4 whitespace-nowrap">
                        Jan Petermann
                      </td>
                    </OddRow>
                    <EvenRow>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                        Lab cleaning
                      </td>
                      <td className="text-sm  font-light px-6 py-4 whitespace-nowrap">
                        Benedict Tohermes
                      </td>
                    </EvenRow>
                    <OddRow>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                        {' '}
                        Lab coats & shoes
                      </td>
                      <td className="text-sm  font-light px-6 py-4 whitespace-nowrap">
                        Robert Fischer
                      </td>
                    </OddRow>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
