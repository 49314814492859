import * as React from 'react'
import { PrimaryButton } from '../components/snippets/buttons'

export class AutheliaState extends React.Component {
  // Constructor
  constructor (props) {
    super(props)

    this.state = {
      username: '',
      auth_level: 0,
      dataIsLoaded: false
    }
  }

  componentDidMount () {
    fetch('/authelia/api/state', {
      method: 'get'
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          username: json.data.username,
          auth_level: json.data.authentication_level,
          dataIsLoaded: true
        })
      })
  }

  render () {
    const { dataIsLoaded, username, auth_level: authLevel } = this.state
    if (!dataIsLoaded) {
      return (
        <a
          className={'mt-5 md:mt-0'}
          href={'/authelia/?rd=https://lasnq.physnet.uni-hamburg.de'}
        >
          <PrimaryButton>Unknown login state</PrimaryButton>
        </a>
      )
    }
    if (authLevel === 0) {
      return (
        <>
          <a
            className={'mt-5 md:mt-0'}
            href={'/authelia/?rd=https://lasnq.physnet.uni-hamburg.de'}
          >
            <PrimaryButton>Login</PrimaryButton>
          </a>
        </>
      )
    }
    return (
      <>
        <a
          className={'mt-5 md:mt-0'}
          href={'/authelia/logout?rd=https://lasnq.physnet.uni-hamburg.de'}
        >
          <PrimaryButton>Logout {username}</PrimaryButton>
        </a>
      </>
    )
  }
}
