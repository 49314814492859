import * as React from 'react'
import { Link } from 'gatsby'
import { AutheliaState } from '../authelia/state'
import PropTypes from 'prop-types'

export function PageHeader (props) {
  return (
    <header className="text-gray-600 body-font pt-5">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <nav className="flex lg:w-2/5 flex-wrap items-center text-base md:ml-auto">
          <Link
            to={'/'}
            className="mr-5 text-gray-600 dark:text-gray-200 hover:text-pink-500"
          >
            Home
          </Link>
          <Link
            to={'/archive/'}
            className="mr-5 text-gray-600 dark:text-gray-200 hover:text-pink-500"
          >
            Archive
          </Link>
          <Link
            to={'/lab27/'}
            className="mr-5 text-gray-600 dark:text-gray-200 hover:text-pink-500"
          >
            Lab 27
          </Link>
          <Link
            to={'/contact/'}
            className="text-gray-600 dark:text-gray-200 hover:text-pink-500"
          >
            Contact Persons
          </Link>
        </nav>
        <a className="flex order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-pink-500 lg:items-center lg:justify-center mb-4 md:mb-0">
          <span className="text-xl">
            <h2 className="uppercase tracking-wide text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 text-3xl text-center lg:text-4xl lg:mt-5">
              {props.children}
            </h2>
          </span>
        </a>
        <div className="lg:w-2/5 inline-flex lg:justify-end lg:ml-0 md:ml-10 xs:ml-0 ml-0">
          <AutheliaState />
        </div>
      </div>
      <div className={'container flex justify-end'}></div>
    </header>
  )
}
PageHeader.propTypes = {
  children: PropTypes.node
}
