import * as React from 'react'
import PropTypes from 'prop-types'

export function Layout (props) {
  return (
    <div className="bg-white dark:bg-gray-900 min-h-screen">
      {props.children}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}
