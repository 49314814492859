import * as React from 'react'
import PropTypes from 'prop-types'

export function OddRow (props) {
  return (
    <tr className="bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-100 border-b dark:border-gray-700">
      {props.children}
    </tr>
  )
}

OddRow.propTypes = {
  children: PropTypes.node
}

export function EvenRow (props) {
  return (
    <tr className="bg-white text-gray-900 dark:bg-gray-800 border-b dark:text-gray-100 dark:border-gray-700">

      {props.children}
    </tr>
  )
}

EvenRow.propTypes = {
  children: PropTypes.node
}
